var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("h2", [_vm._v(_vm._s(_vm.title))]),
      _c(
        "div",
        { staticClass: "search-toolbar-wrapper" },
        [_c("search-toolbar", { on: { search: _vm.search } })],
        1
      ),
      _c(
        "ejs-grid",
        {
          ref: "appointment-grid",
          attrs: {
            dataSource: _vm.data,
            allowPaging: true,
            allowFiltering: true,
            allowSorting: true,
            toolbar: _vm.toolbarOptions,
            toolbarClick: _vm.toolbarClick,
            sortSettings: _vm.sortOptions,
            filterSettings: _vm.filterOptions,
            pageSettings: _vm.pageSettings,
            commandClick: _vm.commandClick
          }
        },
        [
          _c(
            "e-columns",
            [
              _c("e-column", { attrs: { type: "checkbox", width: "50" } }),
              _c("e-column", {
                attrs: {
                  field: "AppointmentId",
                  headerText: _vm.$t("shared.id"),
                  isPrimaryKey: "true",
                  width: "0",
                  visible: false
                }
              }),
              _c("e-column", {
                attrs: {
                  field: "CustomerId",
                  headerText: "Id",
                  width: "0",
                  visible: false
                }
              }),
              _c("e-column", {
                attrs: {
                  field: "CustomerFullName",
                  headerText: _vm.$t("customers.customer"),
                  width: "160",
                  filter: _vm.textBoxFilter
                }
              }),
              _c("e-column", {
                attrs: {
                  field: "StartDateTime",
                  headerText: _vm.$t("calendar.startTime"),
                  width: "80",
                  type: "dateTime",
                  format: { type: "dateTime", skeleton: "short" }
                }
              }),
              _c("e-column", {
                attrs: {
                  field: "EndDateTime",
                  headerText: _vm.$t("calendar.endTime"),
                  width: "80",
                  type: "dateTime",
                  format: { type: "dateTime", skeleton: "short" }
                }
              }),
              _c("e-column", {
                attrs: { field: "OfficeId", width: "0", visible: false }
              }),
              _c("e-column", {
                attrs: {
                  field: "OfficeName",
                  headerText: _vm.$t("offices.office"),
                  width: "100",
                  visible: _vm.hasMultipleOffices
                }
              }),
              _c("e-column", {
                attrs: {
                  field: "ServiceName",
                  headerText: _vm.$t("services.service"),
                  width: "120"
                }
              }),
              _c("e-column", {
                attrs: {
                  field: "EmployeeFullName",
                  headerText: _vm.$t("employees.employee"),
                  width: "160"
                }
              }),
              _c("e-column", {
                attrs: {
                  field: "IsTrial",
                  headerText: _vm.$t("calendar.trial"),
                  width: "80",
                  valueAccessor: _vm.yesNoGridValueMapper,
                  filter: _vm.yesNoDropDownFilter
                }
              }),
              _c("e-column", {
                attrs: {
                  field: "Cost",
                  headerText: _vm.$t("shared.cost"),
                  width: "80",
                  type: "number",
                  format: "C2"
                }
              }),
              _c("e-column", {
                attrs: {
                  field: "IsPaid",
                  headerText: _vm.$t("payments.paid"),
                  width: "80",
                  valueAccessor: _vm.yesNoGridValueMapper,
                  filter: _vm.yesNoDropDownFilter
                }
              }),
              _c("e-column", {
                attrs: {
                  field: "PaymentDate",
                  headerText: _vm.$t("payments.paymentDate"),
                  width: "80",
                  type: "date",
                  format: "yMd"
                }
              }),
              _c("e-column", {
                attrs: {
                  field: "PaymentNotes",
                  headerText: _vm.$t("payments.paymentNote"),
                  width: "250"
                }
              }),
              _c("e-column", {
                attrs: { width: "120", commands: _vm.commands }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("appointments-payment-dialog", {
        ref: "appointmentsPaymentDialog",
        attrs: {
          appointments: _vm.selectedAppointments,
          onClose: _vm.onAppointmentsPaymentDialogClose
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }